import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-online-training',
  templateUrl: './online-training.component.html',
  styleUrls: ['./online-training.component.css']
})
export class OnlineTrainingComponent implements OnInit {

  modaVideo: SafeHtml;

  constructor(private sanitizer: DomSanitizer, private api: ApiService, public cms: CommonService, private swa: SweetAlertService) { }

  ngOnInit() {
    if (!this.cms.plans) {
      this.api.getAllPlans().subscribe(res => {
        const response: any = res;
        this.cms.plans = response.success;
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }

    let source = '<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/JbSl281e65s" allowfullscreen></iframe>'
    this.modaVideo = this.sanitizer.bypassSecurityTrustHtml(source)
  }
}
