import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Countries from '../../../assets/countries.json';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  Countries

  constructor(private api: ApiService, private router: Router, private swa: SweetAlertService, private cms: CommonService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.Countries = Countries.sort()
    // this.Countries = Countries.sort((a, b) => (a.callingCodes[0] > b.callingCodes[0]) ? 1 : -1)
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      lastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, Validators.email]],
      countryCode: ['961', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      repeat_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
    });
  }

  get f(): any { return this.registerForm.controls; }

  OnCountryCodeOptionClicked(value) {
    this.registerForm.controls['countryCode'].setValue(value);
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.valid) {
      if (this.registerForm.value.password !== this.registerForm.value.repeat_password) {
        this.swa.ShowAlert('Error', "Password doesn't match", 'error')
      } else {
        this.api.postSignup(this.registerForm.value).subscribe(res => {
          var response: any = res;
          this.api.Is_Logged_In.next(true)
          this.api.AuthToken = response.token;
          this.cms.setCookie('token', response.token)
          this.cms.UserInfoSubmitted = 0;
          if (response.error) {
            this.swa.ShowAlert('Error', response.error, 'error')
            return 0;
          }
          // if (this.cms.getCookie('path')) {
          //   this.router.navigateByUrl('plan-info/' + this.cms.getCookie('path'));
          //   this.cms.deleteCookie('path')
          // } else {
            this.router.navigateByUrl('training-schedule');
          // }
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    }
  }
}
