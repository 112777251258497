import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
  UserInfoSubmitted;
  plans: any;
  services: any;
  insta_posts: any = []
  trainers: any = [
    {
      id: 1,
      name: "Dr. Boutros Finianos",
      title: "Founder/Owner",
      subTitle: "Strength and Conditioning Coach. Personal Trainer.",
      description: [
        "My name is Boutros Finianos. I hold a Ph.D. in Sports Science from the University of the Littoral Opal Coast-France and a Master's degree in Strength and Conditioning from the University of Balamand-Lebanon.",
        "I am the owner and founder of Gravity gym and the Co-owner of The Gymnasium by Gravity Gym. I do scientific research related to physical activity, health, and physical performance. I work with athletes and recreational people of all ages to help them achieve their goals.",
        "My main goal is to help you learn how to love exercise rather than consider it a must-do. I will introduce you to the correct forms of exercise. I respect your goals and love watching you change and become happier."
      ],
      img: "trainer_profile1.jpg"
    },
    {
      id: 2,
      name: "Hassan Aoun",
      title: "Personal Trainer",
      subTitle: "Strength and Conditioning Coach. Personal Trainer.",
      description: [
        "Holding a Master’s Degree (MSc.) in Strength and Conditioning (S&C) from the University of Nantes, France, with 7+ years of experience in coaching and personal training for a wide variety of the population ranging from the youth to the elderly and even professional athletes whether at the gym, virtually, at home, or even in schools.",
        "My purpose is to help people reach their physical fitness goals through a professional coaching journey while educating them about the importance of pursuing a healthy lifestyle that will reflect positively, not only on their physical but also on mental and emotional health."
      ],
      img: "trainer_profile2.jpg"
    },
    {
      id: 3,
      name: "Joe Frangieh",
      title: "Owner at Jovial Meals",
      subTitle: "MSc in food science and technology",
      description: [
        "Providing healthy meals and post-workout meals"
      ],
      img: "trainer_profile3.jpg"
    },
    {
      id: 4,
      name: "Sarkis Tetone",
      title: "Personal Trainer",
      subTitle: "Physical Education student",
      description: [
        "My name is Sarkis Tetone. I am a Physical Education student at the University of Antonine. It has been 2 years that I work as an assistant personal training coach at Gravity Gym.",
        "My main objective is to help people to achieve their physical fitness goals and help them become healthier, happier, and a better version of themselves. I am happy to be part of the Gravity Gym family."
      ],
      img: "trainer_profile4.jpg"
    },
  ]
  jovial_meals: any = [
    {
      id: 1, title: "Cold sandwiches:",
      data: [
        { id: 1, title: "Crab sandwich", protein: 11.8, calories: 265, img: "crab_sandwich.jpg" },
        { id: 2, title: "Veggies & egg sandwich", protein: 15.5, calories: 280, img: "veggie_egg_sandwich.jpg" },
        { id: 3, title: "Club sandwich", protein: 68.8, calories: 586, img: "club_sandwich.jpg" },
        { id: 4, title: "Turkey & cheese", protein: 18.5, calories: 288, img: "turkey_and_sandwich.jpg" },
        { id: 5, title: "Tuna salad toast", protein: 36, calories: 410, },
        { id: 6, title: "Egg salad sandwich", protein: 20, calories: 413, },
        { id: 7, title: "Spicy egg sandwich", protein: 22, calories: 390, img: "spicy_egg_sandwich.jpg" },
        { id: 8, title: "Avocado sandwich", protein: 15, calories: 350, img: "avocado_sandwich.jpg" },
        { id: 9, title: "Turkey guacamole wrap", protein: 16.5, calories: 293, img: "turkey_guacamole_wrap.jpg" },
        { id: 10, title: "Chicken salad sandwich", protein: 20, calories: 320, img: "chicken_salad.jpg" },
        { id: 11, title: "Grilled Halloumi sandwich", protein: 31, calories: 342, },
        { id: 12, title: "Labneh sandwich", protein: 8, calories: 310, img: "labneh_sandwich.jpg" },
        { id: 13, title: "Chanklich sandwich", protein: 13.5, calories: 430, img: "chanklich_sandwich.jpg" },
      ]
    },
    {
      id: 2, title: "Salads:",
      data: [
        { id: 14, title: "Mexican chickpea salad", protein: 7, calories: 145.5, img: "chickpea_salad.jpg" },
        { id: 15, title: "Quinoa salad", protein: 11.6, calories: 174.6, },
        { id: 16, title: "Halloumi quinoa salad", protein: 12.2, calories: 511, img: "halloumi_quinoa_salad.jpg" },
        { id: 17, title: "Tuna pasta salad", protein: 31, calories: 307, img: "tuna_pasta_salad.jpg" },
        { id: 18, title: "Chicken caesar salad", protein: 27.5, calories: 196.8, },
        { id: 19, title: "Crab salad", protein: 11.5, calories: 407.8, img: "crab_salad.jpg" },
        { id: 20, title: "Avocado salad", protein: 5, calories: 195, img: "avocado_salad.jpg" },
      ]
    },
    {
      id: 3, title: "Lunch boxes:",
      data: [
        { id: 21, title: "Chicken box", protein: 35, calories: 476.25, img: "chicken_box.jpg" },
        { id: 22, title: "Fajita box", protein: 25.5, calories: 416.6, img: "fajita_box.jpg" },
        { id: 23, title: "Beef box", protein: 41.5, calories: 606, img: "beef_box.jpg" },
      ]
    }
  ]
  shopping_cart: any = []

  setCookie(cname, cvalue) {
    const exdays = 30;
    var secure = '';
    if (window.location.protocol === 'https') {
      secure = "secure;";
    }
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + secure + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(cname) {
    document.cookie = cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  }

  loadScripts() {
    var scripts = [
      "assets/js/jquery.fancybox.pack.js",
      "assets/js/jquery.fancybox-media.js",
      "assets/js/owl.js",
      "assets/js/validate.js",
      "assets/js/mixitup.min.js",
      "assets/js/wow.js",
      "assets/js/jquery.countTo.js",
      "assets/js/jquery.countdown.js",
      "assets/js/jquery-ui.js",
      "assets/js/my-scripts.js",
      "assets/js/custom.js",
    ];

    for (let i in scripts) {
      const node = document.createElement('script');
      node.src = scripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  secondToMinutes(time) {
    if (time <= 60) {
      return time + " secs";
    }
    else {
      var minutes = Math.floor(time / 60);
      var seconds = time - minutes * 60;
      if (seconds === 0) {
        return minutes + " min ";
      } else {
        return minutes + " min " + seconds + " secs";
      }
    }
  }
}
