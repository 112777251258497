import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f(): any { return this.resetForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.resetForm.valid) {
      this.api.postSendResetPasswordLink(this.resetForm.value).subscribe(res => {
        const response: any = res;
        if (response.error) {
          this.swa.ShowAlert('Error', response.error, 'error')
        } else {
          this.swa.ShowAlert('Success', 'Check your email', 'success')
        }
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
  }


}
