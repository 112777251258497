import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-jovial',
  templateUrl: './jovial.component.html',
  styleUrls: ['./jovial.component.css']
})
export class JovialComponent implements OnInit {

  constructor(public cms: CommonService,) { }

  ngOnInit() {
  }

  onChangeQuantity(index, value) {
    this.cms.shopping_cart[index].count = +value
  }

  onRemoveItem(index) {
    this.cms.shopping_cart.splice(index, 1)
  }

  onCheckout() {
    const breakLine = "\n"
    const baseUrl = "https://api.whatsapp.com/send/?phone=9613490117&text=Hello"
    let url = breakLine
    this.cms.shopping_cart.forEach((element, index) => {
      url += element.title + " (x" + element.count + ")" + (index < this.cms.shopping_cart.length - 1 ? breakLine : "")
    });
    url = baseUrl + encodeURIComponent(url)
    window.open(url, '_blank');
  }

  addToCart(category_index, item_index) {
    const itemExistIndex = this.cms.shopping_cart.findIndex(el => el.id == this.cms.jovial_meals[category_index].data[item_index].id)
    if (itemExistIndex != -1) {
      this.cms.shopping_cart[itemExistIndex].count++
    } else {
      this.cms.shopping_cart.push({ ...this.cms.jovial_meals[category_index].data[item_index], count: 1 })
    }
  }

  getItemCount(id) {
    if (this.cms.shopping_cart.find(el => el.id == id)) {
      return "(" + this.cms.shopping_cart.find(el => el.id == id).count + ")"
    } else {
      return ""
    }
  }
}
