import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, public cms: CommonService) { }

  URL = window.location.host.startsWith('localhost') ? 'http://localhost:8000/api/' : 'https://api.gravity-gym.com/api/'

  AuthToken = '';
  Is_Logged_In = new BehaviorSubject<boolean>(true);
  showLoader = false;

  // getInstagramMedia(next_max_id) {
  //     this.http.get('https://api.instagram.com/v1/users/self/media/recent?client_id=a53c3e252cae4f31920b7ae3a940d50e&access_token=1491919471.a53c3e2.0a3bbd33ab884266a72fc62dff61f6db&max_id=' + next_max_id).subscribe(res => {
  //         var response: any = res;
  //         this.cms.insta_posts = this.cms.insta_posts.concat(response.data);
  //         if (response.pagination.next_max_id) {
  //             this.getInstagramMedia(response.pagination.next_max_id)
  //         } else {
  //             console.log(this.cms.insta_posts)
  //         }
  //     })
  // }

  // 17547070972.d094897.8e3979feb11c4909929af0729fcd0be7
  // 1491919471.a53c3e2.0a3bbd33ab884266a72fc62dff61f6db
  getInstagramMedia() {
    return this.http.get('https://api.instagram.com/v1/users/self/media/recent?client_id=d09489798d1748adbc08dd1e983e396e&access_token=17547070972.d094897.be9fbac32a6b43f3947c846a28a128c3&count=30')
  }

  postLogin(data: any) {
    this.showLoader = true;
    return this.http.post(this.URL + 'login', data);
  }

  getAttempt() {
    this.showLoader = true;
    return this.http.get(this.URL + 'attempt', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    })
  }

  postSignup(data: any) {
    this.showLoader = true;
    return this.http.post(this.URL + 'signup', data);
  }

  postSendResetPasswordLink(data: any) {
    this.showLoader = true;
    return this.http.post(this.URL + 'sendResetPasswordLink', data);
  }

  postResetPassword(data: any) {
    this.showLoader = true;
    return this.http.post(this.URL + 'resetPassword', data);
  }

  postChangePassword(data: any) {
    this.showLoader = true;
    return this.http.post(this.URL + 'changePassword', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    })
  }

  getUserTrainings() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getUserTrainings', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getUserInfo() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getUserInfo', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getTrainingWorkouts(trainingID) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getTrainingWorkouts/' + trainingID, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getHistoryTrainingWorkouts(trainingID) {
    this.showLoader = true;
    return this.http.get(this.URL + 'getHistoryTrainingWorkouts/' + trainingID, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getHistoryTrainings() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getHistoryTrainings', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postAddUserTrainingFeedback(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addUserTrainingFeedback', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postAddContactUs(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addContactUs', data);
  }

  postAddFitnessAndNutritionQuestions(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addFitnessAndNutritionQuestions', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postAddIndividulAssessments(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addIndividulAssessments', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  getAllAssessments() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllAssessments', {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postSendPlanRequest(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'sendPlanRequest', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  // no enc
  getAllPlans() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllPlans');
  }

  // no enc
  getAllServices() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getAllServices');
  }

  // no enc
  getOpenClasses() {
    this.showLoader = true;
    return this.http.get(this.URL + 'getOpenClasses');
  }

  postSendUserBookingRequest(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'sendUserBookingRequest', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }
  postSendAnonBookingRequest(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'sendAnonUserBookingRequest', data);
  }

  postAddPrePostImage(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'addPrePostImage', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }

  postDeletePrePostImage(data) {
    this.showLoader = true;
    return this.http.post(this.URL + 'deletePrePostImage', data, {
      headers: new HttpHeaders({
        'Authorization': this.AuthToken
      })
    });
  }
}
