import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  data: any;
  pre_image = null
  post_image = null
  formData = new FormData();

  constructor(private api: ApiService, private swa: SweetAlertService, private cms: CommonService) { }

  ngOnInit() {
    this.api.getUserInfo().subscribe(res => {
      const response: any = res;
      this.data = response.success

      this.pre_image = response.success.user_pre_image
      this.post_image = response.success.user_post_image

    }, err => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  OnChooseFile(e, type) {
    let elem = e.target;
    const fileToUpload = elem.files[0];
    this.swa.ShowAlertConfirmation('Are you sure?', 'Media will be added', 'warning').then((result) => {
      if (result.value) {
        this.formData.set('type', type)
        this.formData.set('attachments', fileToUpload);
        this.api.postAddPrePostImage(this.formData).subscribe(res => {
          const response: any = res;
          if (type == 'pre') {
            this.pre_image = response.success
          } else {
            this.post_image = response.success
          }
          this.swa.ShowAlert('Success', 'Media added successfully', 'success')
        }, err => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }

  OnDeleteFile(type) {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Media will be deleted', 'warning').then((result) => {
      if (result.value) {
        const id = type == 'pre' ? this.pre_image.id : this.post_image.id
        this.api.postDeletePrePostImage({ id }).subscribe(res => {
          this.swa.ShowAlert('Success', 'Media deleted successfully', 'success')
          if (type == 'pre') {
            this.pre_image = null
          } else {
            this.post_image = null
          }
        }, err => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
