import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  statsForm: FormGroup;
  stats_submitted = false;

  showAddNew = false;

  regEx_Decimal = '^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$';
  regEx_Integer = '^[1-9][0-9]*$';
  regEx_Integer_Zero = '^[0-9][0-9]*$';

  pattern_message = 'Input type must be a valid number'
  required_message = 'This field is required'

  data: any;

  constructor(private formBuilder: FormBuilder, private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
    this.statsForm = this.formBuilder.group({
      // Body composition measures Score or result Classification
      height: [null, [Validators.pattern(this.regEx_Decimal)]],
      weight: [null, [Validators.pattern(this.regEx_Decimal)]],
      bmi: null,
      bodyFat: [null, [Validators.pattern(this.regEx_Decimal)]],
      fatMass: [null, [Validators.pattern(this.regEx_Decimal)]],
      leanMass: [null, [Validators.pattern(this.regEx_Decimal)]],
      bmr: [null, [Validators.pattern(this.regEx_Decimal)]],
      waistCircumference: [null, [Validators.pattern(this.regEx_Decimal)]],
      hipCircumference: [null, [Validators.pattern(this.regEx_Decimal)]],
      waistToHipRatio: null,

      // Vital signs Score or result Classification
      // restingBloodPressure: [null, [Validators.pattern(this.regEx_Decimal)]],
      // restingHeartRate: [null, [Validators.pattern(this.regEx_Decimal)]],

      // Cardiorespiratory endurance Score or result Classification
      // voTwoMax: [null, [Validators.pattern(this.regEx_Decimal)]],
      // otherCardiorespiratoryEndurance: null,

      // Muscular endurance Score or result Classification
      pushups: [null, [Validators.pattern(this.regEx_Integer)]],
      situps: [null, [Validators.pattern(this.regEx_Integer)]],
      pullups: [null, [Validators.pattern(this.regEx_Integer)]],
      otherMuscularEndurance: null,

      // Muscular strength Score or result Classification
      benchPress: [null, [Validators.pattern(this.regEx_Decimal)]],
      legPress: [null, [Validators.pattern(this.regEx_Decimal)]],
      otherMuscularStrength: null,

      // Flexibility Score or result Classification
      // sitAndReach: [null, [Validators.pattern(this.regEx_Decimal)]],
      // otherFlexibilityScore: null,

      // Other tests Score or result Classification
      // otherTestsScore: null,
      // comments: null,
    })

    this.getAllAssessments()

    setInterval(() => {
      this.calculateBMI();
      this.calculateWaistHipRatio()
    }, 1000)
  }

  getAllAssessments() {
    this.api.getAllAssessments().subscribe(res => {
      var response: any = res;
      this.data = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  get f(): any { return this.statsForm.controls; }

  onShowHideAdd() {
    this.showAddNew = !this.showAddNew;
  }

  calculateBMI() {
    if (this.statsForm.get('weight').value && this.statsForm.get('height').value) {
      var bmi = this.statsForm.get('weight').value /
        Math.pow(this.statsForm.get('height').value / 100, 2);
      this.statsForm.controls['bmi'].setValue(bmi);
    } else {
      this.statsForm.controls['bmi'].setValue(null);
    }
  }

  calculateWaistHipRatio() {
    if (this.statsForm.get('waistCircumference').value && this.statsForm.get('hipCircumference').value) {
      var ratio = this.statsForm.get('waistCircumference').value /
        this.statsForm.get('hipCircumference').value;
      this.statsForm.controls['waistToHipRatio'].setValue(ratio);
    } else {
      this.statsForm.controls['waistToHipRatio'].setValue(null);
    }
  }

  onSubmit() {
    this.stats_submitted = true;
    if (this.statsForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'You will not be able to edit!', 'warning').then((result) => {
        if (result.value) {
          this.api.postAddIndividulAssessments(this.statsForm.value).subscribe(res => {
            this.getAllAssessments()
            this.onShowHideAdd()
            scrollTo(0, 0)
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }
}
