import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { LoginComponent } from './components/login/login.component';
import { TrainerProfileComponent } from './components/trainer-profile/trainer-profile.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { HistoryComponent } from './components/my-profile/history/history.component';
import { CanActivateThisRoute } from './core/guard/router.guard';
import { RegisterComponent } from './components/register/register.component';
import { TrainingScheduleComponent } from './components/training-schedule/training-schedule.component';
import { OnlineTrainingComponent } from './components/online-training/online-training.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/my-profile/change-password/change-password.component';
import { ResetPasswordTokenComponent } from './components/login/reset-password-token/reset-password-token.component';
import { StatsComponent } from './components/my-profile/stats/stats.component';
import { PlanInfoComponent } from './components/online-training/plan-info/plan-info.component';
import { ServiceInfoComponent } from './components/home/service-info/service-info.component';
import { BookingComponent } from './components/home/service-info/booking/booking.component';
import { ProfileComponent } from './components/my-profile/profile/profile.component';
import { TheGymnasiumComponent } from './components/the-gymnasium/the-gymnasium.component';
import { JovialComponent } from './components/jovial/jovial.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { title: "Home" } },
  { path: 'service-info/:id', component: ServiceInfoComponent, },
  { path: 'booking/:id', component: BookingComponent, data: { title: "Booking" } },
  { path: 'trainer-profile/:id', component: TrainerProfileComponent },
  { path: 'about', component: AboutComponent, data: { title: "About" } },
  // { path: 'blog', component: BlogsComponent },
  { path: 'contact', component: ContactComponent, data: { title: "Contact" } },
  { path: 'training-schedule', component: TrainingScheduleComponent, canActivate: [CanActivateThisRoute], data: { title: "Training Schedule" } },
  { path: 'online-training', component: OnlineTrainingComponent, data: { title: "Online Training" } },
  { path: 'plan-info/:id', component: PlanInfoComponent },
  { path: 'history', component: HistoryComponent, canActivate: [CanActivateThisRoute], data: { title: "History" } },
  { path: 'login', component: LoginComponent, data: { title: "Login" } },
  { path: 'reset-password', component: ResetPasswordComponent, data: { title: "reset-password" } },
  { path: 'reset-password/:token', component: ResetPasswordTokenComponent, data: { title: "Change Password" } },
  { path: 'stats', component: StatsComponent, canActivate: [CanActivateThisRoute], data: { title: "Stats" } },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [CanActivateThisRoute], data: { title: "Change Password" } },
  { path: 'register', component: RegisterComponent, data: { title: "Register" } },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [CanActivateThisRoute], data: { title: "My Profile" } },
  { path: 'profile', component: ProfileComponent, canActivate: [CanActivateThisRoute], data: { title: "Profile" } },
  { path: 'the-gymnasium', component: TheGymnasiumComponent, data: { title: "The Gymnasium" } },
  { path: 'jovial', component: JovialComponent, data: { title: "Jovial" } },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
