import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  constructor(private api: ApiService, private router: Router, private swa: SweetAlertService, private cms: CommonService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (this.api.AuthToken) {
      this.api.Is_Logged_In.next(true);
      this.router.navigateByUrl('home')
      return 0;
    }
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]]
    });
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.valid) {
      this.api.postLogin(this.loginForm.value).subscribe(res => {
        var response: any = res;
        if (response.error) {
          this.swa.ShowAlert('Error', response.error, 'error')
          return 0;
        }
        response = response.success;
        if (response.role === 'user') {
          this.api.Is_Logged_In.next(true)
          this.api.AuthToken = response.token;
          this.cms.setCookie('token', response.token)
          this.cms.UserInfoSubmitted = +response.info_submitted;
          if (this.cms.getCookie('path')) {
            this.router.navigateByUrl('plan-info/' + this.cms.getCookie('path'));
            this.cms.deleteCookie('path')
          } else {
            this.router.navigateByUrl('home');
          }
        } else {
          this.api.Is_Logged_In.next(false)
          this.swa.ShowAlert('Error', 'Unauthorized', 'error')
        }
      }, (err) => {
        this.api.showLoader = false;
        this.api.Is_Logged_In.next(false)
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
  }

}
