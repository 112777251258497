import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;
  required_message = 'This field is required'

  constructor(private api: ApiService, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required, Validators.maxLength(100)]],
      message: ['', [Validators.required, Validators.maxLength(500)]]
    });
  }

  get f(): any { return this.contactForm.controls; }

  OnSubmit() {
    this.submitted = true

    if (this.contactForm.valid) {
      this.contactForm.controls['name'].setValue(this.contactForm.get('name').value.trimLeft().trimRight());
      this.contactForm.controls['email'].setValue(this.contactForm.get('email').value.trimLeft().trimRight());
      this.contactForm.controls['subject'].setValue(this.contactForm.get('subject').value.trimLeft().trimRight());
      this.contactForm.controls['message'].setValue(this.contactForm.get('message').value.trimLeft().trimRight());

      this.api.postAddContactUs(this.contactForm.value).subscribe(() => {
        this.swa.ShowAlert('Success', 'Message sent successfully!', 'success')
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
  }

}
