import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-plan-info',
  templateUrl: './plan-info.component.html',
  styleUrls: ['./plan-info.component.css']
})
export class PlanInfoComponent implements OnInit {

  plan: any;

  constructor(private route: ActivatedRoute, private router: Router, private swa: SweetAlertService, private api: ApiService, private cms: CommonService,
    private titleService: Title) {
    if (!this.cms.plans) {
      this.api.getAllPlans().subscribe(res => {
        const response: any = res;
        this.cms.plans = response.success;
        this.findPlan();
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    } else {
      this.api.showLoader = false;
      this.findPlan();
    }
  }

  ngOnInit() { }

  findPlan() {
    this.route.params.subscribe(params => {
      this.plan = this.cms.plans.find(x => x.id === +params.id);
      this.titleService.setTitle("Plan: " + this.plan.title);
      if (!this.plan) {
        this.router.navigateByUrl('online-training')
      }
    });
  }

  onPlanSelected() {
    this.swa.ShowAlertConfirmation('Are you sure?', 'Your request will be sent', 'warning').then((result) => {
      if (result.value) {
        if (!this.api.AuthToken) {
          this.router.navigateByUrl('register');
          this.cms.setCookie('path', this.plan.id);
          return 0;
        } else {
          var data = { planId: this.plan.id }
          this.api.postSendPlanRequest(data).subscribe(res => {
            const response: any = res;
            if (response.error) {
              if (response.type === 'info') {
                this.router.navigateByUrl('training-schedule');
              }
              this.swa.ShowAlert('Warning', response.error, 'warning')
            } else {
              this.swa.ShowAlert('Success', 'Thank you for filling in all the information needed to create a customized fitness/Diet plan. After receiving your payment, your plan is guaranteed to be delivered for a period of maximum 48 hours.', 'success')
              setTimeout(() => {
                this.router.navigateByUrl('training-schedule');
              }, 4000);
            }
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      }
    })
  }
}
