import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  constructor(private api: ApiService, private cms: CommonService) { }

  ngOnInit() { }

  OnLogout() {
    this.cms.deleteCookie('token');
    this.api.Is_Logged_In.next(false);
    document.location.href = "/"
  }
}
