import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  submitted = false;

  constructor(private api: ApiService, private swa: SweetAlertService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      current: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
    });
  }

  get f(): any { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.value.password !== this.changePasswordForm.value.password_confirmation) {
        this.swa.ShowAlert('Error', 'Confirm Password does not match', 'error');
      }
      else {
        this.api.postChangePassword(this.changePasswordForm.value).subscribe(res => {
          const response: any = res;
          if (response.error) {
            this.swa.ShowAlert('Error', response.error, 'error')
          } else {
            this.swa.ShowAlert('Success', 'Password changed!', 'success')
            this.router.navigateByUrl('my-profile')
          }
        }, err => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    }
  }
}
