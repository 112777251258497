import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.css']
})
export class ServiceInfoComponent implements OnInit {

  service: any;
  service_id;

  constructor(private route: ActivatedRoute, private swa: SweetAlertService, private api: ApiService, private router: Router, private cms: CommonService,
    private titleService: Title) {
    this.route.params.subscribe(params => {
      this.service_id = +params.id;
    });
    if (!this.cms.services) {
      this.api.getAllServices().subscribe(res => {
        const response: any = res;
        this.cms.services = response.success;
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
        this.getService();
      })
    } else {
      this.api.showLoader = false;
      this.getService()
    }
  }

  ngOnInit() { }

  getService() {
    this.route.params.subscribe(params => {
      this.service = this.cms.services.find(x => x.id === +params.id);
      this.titleService.setTitle("Service: " + this.service.title);
      if (!this.service) {
        this.router.navigateByUrl('home')
      }
    });
  }

}
