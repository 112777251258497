import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-reset-password-token',
  templateUrl: './reset-password-token.component.html',
  styleUrls: ['./reset-password-token.component.css']
})
export class ResetPasswordTokenComponent implements OnInit {

  changePasswordForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private api: ApiService, private swa: SweetAlertService) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      resetToken: null
    });

    this.route.params.subscribe(params => {
      this.changePasswordForm.controls['resetToken'].setValue(params['token']);
    });
  }

  get f(): any { return this.changePasswordForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.value.password !== this.changePasswordForm.value.password_confirmation) {
        this.swa.ShowAlert('Error', 'Confirm Password does not match', 'error');
      }
      else {
        this.api.postResetPassword(this.changePasswordForm.value).subscribe(res => {
          this.swa.ShowAlert('Success', 'Password changed!', 'success')
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    }
  }
}
