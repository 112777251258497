import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public screen_size = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screen_size = event.target.innerWidth;
  }

  constructor(private api: ApiService, public cms: CommonService, private swa: SweetAlertService) { }

  ngOnInit() {

    // this.api.getInstagramMedia(null)

    // if (!localStorage.data) {
    //   this.api.getInstagramMedia().subscribe(res => {
    //     var response: any = res;
    //     this.cms.insta_posts = response.data
    //     localStorage.data = JSON.stringify(response.data)
    //   })
    // } else {
    //   this.cms.insta_posts = JSON.parse(localStorage.data)
    // }

    if (!this.cms.services) {
      this.api.getAllServices().subscribe(res => {
        const response: any = res;
        this.cms.services = response.success;
      }, (err) => {
        this.api.showLoader = false;
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
        this.cms.loadScripts();
      })
    }
  }
}
