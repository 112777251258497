import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { ApiService } from './core/services/api.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { LoginComponent } from './components/login/login.component';
import { TrainerProfileComponent } from './components/trainer-profile/trainer-profile.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { HistoryComponent } from './components/my-profile/history/history.component';
import { CanActivateThisRoute } from './core/guard/router.guard';
import { SweetAlertService } from './core/services/sweetalert.service';
import { CommonService } from './core/services/common.service';
import { RegisterComponent } from './components/register/register.component';
import { TrainingScheduleComponent } from './components/training-schedule/training-schedule.component';
import { OnlineTrainingComponent } from './components/online-training/online-training.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/my-profile/change-password/change-password.component';
import { ResetPasswordTokenComponent } from './components/login/reset-password-token/reset-password-token.component';
import { FitnessNutritionFormComponent } from './components/fitness-nutrition-form/fitness-nutrition-form.component';
import { StatsComponent } from './components/my-profile/stats/stats.component';
import { PlanInfoComponent } from './components/online-training/plan-info/plan-info.component';
import { ServiceInfoComponent } from './components/home/service-info/service-info.component';
import { BookingComponent } from './components/home/service-info/booking/booking.component';
import { EncryptionService } from './core/services/encryption.service';
import { LoaderComponent } from './components/loader/loader.component';
import { ProfileComponent } from './components/my-profile/profile/profile.component';
import { TheGymnasiumComponent } from './components/the-gymnasium/the-gymnasium.component';
import { JovialComponent } from './components/jovial/jovial.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    LoginComponent,
    TrainerProfileComponent,
    MyProfileComponent,
    HistoryComponent,
    RegisterComponent,
    TrainingScheduleComponent,
    OnlineTrainingComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordTokenComponent,
    FitnessNutritionFormComponent,
    StatsComponent,
    PlanInfoComponent,
    ServiceInfoComponent,
    BookingComponent,
    LoaderComponent,
    ProfileComponent,
    TheGymnasiumComponent,
    JovialComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FullCalendarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
  ],
  providers: [ApiService, CanActivateThisRoute, SweetAlertService, CommonService, EncryptionService, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
