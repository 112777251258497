import { Component, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { OptionsInput } from '@fullcalendar/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  options: OptionsInput;
  calendarEvents: any = [];
  showForm = false;
  bookingForm: FormGroup;
  submitted = false;

  constructor(private api: ApiService, private swa: SweetAlertService, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.bookingForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      lastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
      classId: 0
    });

    this.options = {
      header: {
        left: 'prev, next, today',
        center: 'title',
        right: 'dayGridMonth, timeGridWeek, timeGridDay',
      },
      allDayText: 'Time',
      minTime: "08:00:00",
      maxTime: "24:00:00",
      defaultView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin]
    };
    this.api.getOpenClasses().subscribe(res => {
      var response: any = res;
      var data: any = response.success;
      for (let item of data) {
        this.calendarEvents.push({
          id: item.id, title: item.title, description: item.description,
          start: item.startDate, end: item.endDate, reserved: item.reserved,
          color: '#4CAF50'
        })
      }
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  get f(): any { return this.bookingForm.controls; }


  eventClick(e) {
    this.bookingForm.controls['classId'].setValue(+e.event.id);
    if (this.api.AuthToken) {
      this.showForm = false;
      this.swa.ShowAlertConfirmation('Are you sure?', 'An email will be send to confirm your request.', 'warning').then((result) => {
        if (result.value) {
          const data = { classId: this.bookingForm.get('classId').value };
          this.api.postSendUserBookingRequest(data).subscribe(res => {
            const response: any = res;
            if (response.error) {
              this.swa.ShowAlert('Warning', 'Class has been reserved! Reload your page.', 'warning')
              return 0;
            }
            this.swa.ShowAlert('Success', 'Request sent successfully! We will contact you soon.', 'success')
          }, (err) => {
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    } else {
      this.api.showLoader = false;
      this.showForm = true;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.bookingForm.valid) {
      this.swa.ShowAlertConfirmation('Are you sure?', 'An email will be send to confirm your request.', 'warning').then((result) => {
        if (result.value) {
          this.api.postSendAnonBookingRequest(this.bookingForm.value).subscribe(res => {
            this.swa.ShowAlert('Success', 'Request sent successfully! We will contact you soon.', 'success')
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    }
  }
}
