import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { ActivatedRoute, } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-trainer-profile',
  templateUrl: './trainer-profile.component.html',
  styleUrls: ['./trainer-profile.component.css']
})
export class TrainerProfileComponent implements OnInit {

  selected_trainer: any = {};

  constructor(public cms: CommonService, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selected_trainer = this.cms.trainers.find(el => el.id == +params.id)
      this.titleService.setTitle("Trainer: " + this.selected_trainer.name);
    });
  }

}
