import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptionService {

    decrypt(encryptedString) {
        var json = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString)));

        var salt = CryptoJS.enc.Hex.parse(json.salt);
        var iv = CryptoJS.enc.Hex.parse(json.iv);

        var encrypted = json.ciphertext;

        var iterations = parseInt(json.iterations);
        if (iterations <= 0) {
            iterations = 999;
        }
        var encryptMethodLength = (this.encryptMethodLength / 4);
        var time = Math.floor((new Date().getTime()) / 1000);

        for (var i = -5; i < 10; i++) {
            var kt = (time - i).toString();
            var key = kt + kt.substring(5, 10);

            var hashKey = CryptoJS.PBKDF2(key, salt, { 'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength / 8), 'iterations': iterations });

            var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, { 'mode': CryptoJS.mode.CBC, 'iv': iv });
            try {
                return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
            } catch (e) { }
        }
    }

    encrypt(string) {
        string = JSON.stringify(string);
        var keyt = Math.floor((new Date().getTime()) / 1000).toString();
        var key = keyt + keyt.substring(5, 10);

        var iv = CryptoJS.lib.WordArray.random(16);
        var salt = CryptoJS.lib.WordArray.random(256);
        var iterations = 999;
        var encryptMethodLength = (this.encryptMethodLength / 4);
        var hashKey = CryptoJS.PBKDF2(key, salt, { 'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength / 8), 'iterations': iterations });

        var encrypted = CryptoJS.AES.encrypt(string, hashKey, { 'mode': CryptoJS.mode.CBC, 'iv': iv });
        var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

        var output = {
            'ciphertext': encryptedString,
            'iv': CryptoJS.enc.Hex.stringify(iv),
            'salt': CryptoJS.enc.Hex.stringify(salt),
            'iterations': iterations
        };

        return { data: CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(output))) };
    }

    get encryptMethodLength() {
        var encryptMethod = this.encryptMethod;
        var aesNumber = encryptMethod.match(/\d+/)[0];
        return parseInt(aesNumber);
    }

    get encryptKeySize() {
        var aesNumber = this.encryptMethodLength;
        return (aesNumber / 8);
    }

    get encryptMethod() {
        return 'AES-256-CBC';
    }
}
