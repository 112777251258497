import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { CommonService } from 'src/app/core/services/common.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { OptionsInput } from '@fullcalendar/core';

@Component({
  selector: 'app-training-schedule',
  templateUrl: './training-schedule.component.html',
  styleUrls: ['./training-schedule.component.css']
})
export class TrainingScheduleComponent implements OnInit {
  options: OptionsInput;
  header = {
    title: '',
    date: '',
    note: '',
    uri: ''
  }

  data: any = [];
  calendarEvents: any = [];
  SetsArray: any = []
  RepsArray = new Array(100)
  workoutsArray: any = []
  training_id: number;
  postWorkout = {
    before: 0,
    after: 0,
    difficult: 0,
    comment: ''
  }

  modaVideo: SafeHtml;
  setRestCountdownStarted = false;
  exerciceRestCountdownStarted = false;
  workoutTimeCountdownStarted = false;

  constructor(private api: ApiService, private sanitizer: DomSanitizer, private swa: SweetAlertService, public cms: CommonService) { }

  ngOnInit() {
    this.options = {
      header: {
        left: 'prev, next, today',
        center: 'title',
        right: 'dayGridMonth, timeGridWeek, timeGridDay',
      },
      allDayText: 'Time',
      minTime: "08:00:00",
      maxTime: "24:00:00",
      defaultView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin]
    };
    this.api.getUserTrainings().subscribe(res => {
      var response: any = res;
      this.calendarEvents = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  workoutTimeCountdown(seconds, index) {
    if (!this.workoutTimeCountdownStarted) {
      this.workoutTimeCountdownStarted = true
      let audio = new Audio();
      audio.src = "assets/notifications/click.mp3";
      audio.load();
      audio.play();
      var interval = setInterval(() => {
        this.data[index].workoutTime--;
        if (this.data[index].workoutTime < 0) {
          this.data[index].workoutTime = seconds;
          clearInterval(interval);
          audio.src = "assets/notifications/set_rest_notification.mp3";
          audio.load();
          audio.play();
          this.workoutTimeCountdownStarted = false;
        };
      }, 1000);
    }
  };

  setRestCountdown(seconds, index, setIndex) {
    if (!this.setRestCountdownStarted) {
      if (setIndex == this.data[index].nbSets - 1) {
        document.getElementById('set_rest_' + index + '_' + setIndex).scrollIntoView({ behavior: 'smooth' });
      }
      this.setRestCountdownStarted = true
      let audio = new Audio();
      audio.src = "assets/notifications/click.mp3";
      audio.load();
      audio.play();
      var interval = setInterval(() => {
        this.data[index].setsTimeout[setIndex]--
        this.data[index].setRest--;
        if (this.data[index].setRest < 0) {
          this.data[index].setRest = seconds;
          this.data[index].setsTimeout[setIndex] = seconds
          clearInterval(interval);
          audio.src = "assets/notifications/set_rest_notification.mp3";
          audio.load();
          audio.play();
          this.setRestCountdownStarted = false;
        };
      }, 1000);
    }
  };

  exerciceRestCountdown(seconds, index) {
    if (!this.exerciceRestCountdownStarted) {
      document.getElementById('exercice_rest_' + index).scrollIntoView({ behavior: 'smooth' });

      this.exerciceRestCountdownStarted = true
      let audio = new Audio();
      audio.src = "assets/notifications/click.mp3";
      audio.load();
      audio.play();
      var interval = setInterval(() => {
        this.data[index].exerciceRest--;
        if (this.data[index].exerciceRest < 0) {
          this.data[index].exerciceRest = seconds;
          clearInterval(interval);
          audio.src = "assets/notifications/set_rest_notification.mp3";
          audio.load();
          audio.play();
          this.exerciceRestCountdownStarted = false;
        };
      }, 1000);
    }
  };

  showModal(src) {
    setTimeout(() => {
      let source = '<iframe class="embed-responsive-item" src=' + src + ' allowfullscreen></iframe>'
      this.modaVideo = this.sanitizer.bypassSecurityTrustHtml(source)
    }, 200);
  }

  eventClick(e) {
    this.SetsArray = []
    // this.RepsArray = []
    this.workoutsArray = []
    this.postWorkout = {
      before: 0,
      after: 0,
      difficult: 0,
      comment: ''
    }

    this.header.title = e.event.title;
    this.header.date = e.event.start;
    this.header.note = e.event._def.extendedProps.note;
    this.header.uri = e.event._def.extendedProps.uri;

    this.training_id = e.event.id

    this.api.getTrainingWorkouts(this.training_id).subscribe(res => {
      var response: any = res;
      this.data = response.success
      if (this.data) {
        this.data.forEach(element => {
          this.SetsArray.push(Array(+element.nbSets))
          element.min_seconds_exerciceRest = this.cms.secondToMinutes(element.exerciceRest);
          element.min_seconds_setRest = this.cms.secondToMinutes(element.setRest);
          element.min_seconds_workoutTime = this.cms.secondToMinutes(element.workoutTime);

          element.setsTimeout = Array(element.nbSets).fill(element.setRest)


          if (element.minTime !== 0) {
            element.minTime = this.cms.secondToMinutes(element.minTime);
          }
          if (element.maxTime !== 0) {
            element.maxTime = this.cms.secondToMinutes(element.maxTime);
          }

          for (let y = 0; y < element.nbSets; y++) {
            this.workoutsArray.push({ workoutId: element.id, setNumber: +y + 1, setValue: 0 })
          }
          if (element.uri) {
            element.videoURL = element.uri.split("embed/")[1]
          }
        });

        // for (let i in this.data) {
        //   this.RepsArray.push(Array(this.data[i].nbRepetitions))
        // }
        setTimeout(() => {
          document.querySelector('#my-workouts').scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  OnSetsOptionClicked(workoutId, setIndex, setValue) {
    const _wIndex = this.workoutsArray.findIndex(x => x.workoutId === workoutId && x.setNumber === setIndex + 1)
    this.workoutsArray[_wIndex].setValue = +setValue;
  }

  OnPostWorkoutOptionClicked(title, e) {
    switch (title) {
      case 'before':
        this.postWorkout.before = +e
        break;
      case 'after':
        this.postWorkout.after = +e
        break;
      case 'difficult':
        this.postWorkout.difficult = +e
        break;
      default:
        break;
    }
  }

  OnSubmit() {
    var invalidValues = 0;

    this.workoutsArray.forEach(element => {
      if (element.setValue === 0) {
        invalidValues++;
      }
    });

    if (invalidValues !== 0) {
      this.swa.ShowAlert('Error', 'Please fill your sets value!', 'error')
      invalidValues = 0;
      return 0;
    }

    if (this.postWorkout.before === 0 || this.postWorkout.after === 0 || this.postWorkout.difficult === 0) {
      this.swa.ShowAlert('Error', 'Please fill your post workout questions!', 'error')
      return 0;
    }

    var arr = JSON.stringify({ workouts: this.workoutsArray, postworkouts: this.postWorkout })
    const obj = { idTraining: this.training_id, feedback: arr }

    this.swa.ShowAlertConfirmation('Are you sure?', 'You will not be able to edit!', 'warning').then((result) => {
      if (result.value) {
        this.api.postAddUserTrainingFeedback(obj).subscribe(res => {
          window.location.reload()
        }, (err) => {
          this.api.showLoader = false;
          this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
        }, () => {
          this.api.showLoader = false;
        })
      }
    })
  }
}
