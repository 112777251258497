import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class SweetAlertService {
    ShowAlert(title, text, type) {
        return Swal.fire(title, text, type)
    }

    ShowAlertConfirmation(title, text, type) {
        return Swal.fire({
            title: title,
            text: text,
            type: type,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        })
    }
};
