import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-fitness-nutrition-form',
  templateUrl: './fitness-nutrition-form.component.html',
  styleUrls: ['./fitness-nutrition-form.component.css']
})
export class FitnessNutritionFormComponent implements OnInit {

  fitnessForm: FormGroup;
  nutritionForm: FormGroup;
  fitness_submitted = false;
  nutrition_submitted = false;

  first_step = true;
  maxDate = new Date();

  regEx_Decimal = '^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$';
  regEx_Integer = '^[1-9][0-9]*$';
  regEx_Integer_Zero = '^[0-9][0-9]*$';

  pattern_message = 'Input type must be a valid number'
  required_message = 'This field is required'

  injury = [
    { id: 1, name: 'Pain or discomfort in your chest' },
    { id: 2, name: 'Shortness of breath' },
    { id: 3, name: 'Dizziness or fainting' },
    { id: 5, name: 'Difficulty breathing' },
    { id: 6, name: 'Heart palpations' },
    { id: 7, name: 'Heart murmurs' },
    { id: 8, name: 'Pain when you exercise' },
  ]
  injury_arr = []

  goals = [
    { id: 1, name: 'Improve general fitness' },
    { id: 2, name: 'Lose weight' },
    { id: 3, name: 'Tone up' },
    { id: 4, name: 'Add muscle' },
    { id: 5, name: 'Other' },
  ]
  goals_arr = [];

  aboutUS = [
    { id: 1, name: 'Google' },
    { id: 2, name: 'Facebook' },
    { id: 3, name: 'Instagram' },
    { id: 4, name: 'Friends' },
    { id: 5, name: 'Other' },
  ]
  aboutUs_arr = [];

  fitnessLevels = [
    { id: 1, name: 'NOVICE- I am new to fitness' },
    { id: 2, name: 'Beginner- I workout when I can' },
    { id: 3, name: 'INTERMEDIATE- I have a set routine 2-3 times per week' },
    { id: 4, name: 'ADVANCED: I workout 3-5 times a week' },
    { id: 5, name: 'ATHLETE- I regularly compete in fitness competitions' },
  ]
  fitnessLevels_arr = []

  fitnessEquipments = [
    { id: 1, name: 'Dumbbells' },
    { id: 2, name: 'Exercises ball' },
    { id: 3, name: 'Medicine ball' },
    { id: 4, name: 'Body Weight' },
    { id: 5, name: 'Gym' },
    { id: 6, name: 'Bands' },
    { id: 7, name: 'Elliptical' },
    { id: 8, name: 'Treadmill' },
    { id: 9, name: 'Rower' },
    { id: 10, name: 'Other' },
  ]
  fitnessEquipments_arr = []

  workoutPlace = [
    { id: 1, name: 'Home' },
    { id: 2, name: 'Gym' },
  ]
  workoutPlace_arr = []

  workoutSessionTime = [
    { id: 1, name: 'Less than 20 minutes' },
    { id: 2, name: 'Less than 30 minutes' },
    { id: 3, name: '30 - 60 minutes' },
    { id: 4, name: '60 - 90 minutes' },
  ]
  workoutSessionTime_arr = []

  constructor(private api: ApiService, private formBuilder: FormBuilder, private swa: SweetAlertService) { }

  ngOnInit() {
    this.fitnessForm = this.formBuilder.group({
      heartAttackStroke: [null, [Validators.required]],
      heartDiseaseOverFiftyFive: [null, [Validators.required]],
      surgeries: null,
      diabetes: [null, [Validators.required]],
      bloodPressure: [null, [Validators.required]],
      cholesterol: [null, [Validators.required]],
      diagnosedDiseases: [null, [Validators.required]],
      injuryList: null,
      pregnant: [null, [Validators.required]],
      injury: [null, [Validators.required]],
      problem: null,
      permissionToExercice: [null, [Validators.required]],
      medications: null,
      medicationReason: null,
      dosage: [null, Validators.pattern(this.regEx_Decimal)],
      frequency: [null, Validators.pattern(this.regEx_Decimal)],
      height: [null, [Validators.required, Validators.pattern(this.regEx_Decimal)]],
      goals: null,
      specificGoals: [null, [Validators.required]],
      hearAboutUs: [null, [Validators.required]],
      fitnessLevel: [null, [Validators.required]],
      fitnessEquipments: [null, [Validators.required]],
      otherFitnessEquipments: null,
      workoutPlace: [null, [Validators.required]],
      workoutDaysPerWeek: [null, [Validators.required, Validators.pattern(this.regEx_Integer)]],
      currentRoutine: null,
      workoutSessionTime: [null, [Validators.required]],
      pushups: [null, [Validators.pattern(this.regEx_Integer_Zero)]],
      situps: [null, [Validators.pattern(this.regEx_Integer_Zero)]],
      currentJob: null,
      workHoursPerDay: [null, [Validators.pattern(this.regEx_Integer_Zero)]],
      sleepHoursPerDay: [null, [Validators.pattern(this.regEx_Integer)]],
    });

    this.nutritionForm = this.formBuilder.group({
      gender: [null, [Validators.required]],
      dob: [null, [Validators.required]],
      weight: [null, [Validators.required, Validators.pattern(this.regEx_Decimal)]],
      desiredWeight: [null, [Validators.required, Validators.pattern(this.regEx_Decimal)]],
      typicalEatingDay: [null, [Validators.required]],
      sodaPerWeek: [null, [Validators.pattern(this.regEx_Integer_Zero)]],
      alcoholPerWeek: [null, [Validators.pattern(this.regEx_Integer_Zero)]],
      eatSweetsPerWeek: [null, [Validators.pattern(this.regEx_Integer_Zero)]],
      foodAllergies: null,
      proteinShakes: [null, [Validators.required]],
      foodDontEat: [null, [Validators.required]],
      foodMustInclude: null,
      saltyFoodPreferences: null,
      sweetFoodPreferences: null
    })
  }

  get fitness_f(): any { return this.fitnessForm.controls; }
  get nutrition_f(): any { return this.nutritionForm.controls; }

  onCheckboxGroupClick(item, arr, name) {
    const index = arr.findIndex((e) => e.id === item.id);

    if (index === -1) {
      arr.push(item);
    } else {
      arr.splice(index, 1)
    }
    if (arr.length === 0) {
      this.fitnessForm.controls[name].setValue(null);
    } else {
      this.fitnessForm.controls[name].setValue(JSON.stringify(arr));
    }
  }

  onBackClick() {
    this.first_step = true;
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  onSubmitHealth() {
    this.fitness_submitted = true;
    if (this.fitnessForm.valid) {
      this.first_step = false;
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  onSubmitExercice() {
    this.nutrition_submitted = true;
    if (this.nutritionForm.valid) {
      const data = { fitness: this.fitnessForm.value, nutrition: this.nutritionForm.value }
      this.swa.ShowAlertConfirmation('Are you sure?', 'You will not be able to edit!', 'warning').then((result) => {
        if (result.value) {
          this.api.postAddFitnessAndNutritionQuestions(data).subscribe(res => {
            document.location.reload();
          }, (err) => {
            this.api.showLoader = false;
            this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
          }, () => {
            this.api.showLoader = false;
          })
        }
      })
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
}
