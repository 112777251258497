import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiService } from './core/services/api.service';
import { SweetAlertService } from './core/services/sweetalert.service';
import { CommonService } from './core/services/common.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Home';
  FullYear = new Date().getFullYear()

  constructor(private router: Router, private myElement: ElementRef, public api: ApiService, private swa: SweetAlertService, public cms: CommonService,
    private titleService: Title, private activatedRoute: ActivatedRoute) {
    if (this.cms.getCookie('token')) {
      this.api.AuthToken = this.cms.getCookie('token');
      this.api.getAttempt().subscribe(res => {
        var response: any = res;
        response = response.success;
        this.api.AuthToken = response.token;
        this.cms.setCookie('token', response.token);
        this.cms.UserInfoSubmitted = +response.info_submitted;
        this.api.Is_Logged_In.next(true);
      }, err => {
        this.api.showLoader = false;
        this.cms.deleteCookie('token')
        this.api.Is_Logged_In.next(false)
        this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
      }, () => {
        this.api.showLoader = false;
      })
    }
    else {
      this.api.showLoader = false;
      this.api.Is_Logged_In.next(false)
    }
  }

  ngOnInit() {
    this.initializeCookies()
    this.loadScripts();
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return null;
        })
      ).subscribe((ttl: string) => {
        if (ttl) {
          this.titleService.setTitle(ttl);
        }
      });

  }

  onActivate(event) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  initializeCookies() {
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: 'This website use cookies to ensure you get the best experience on our website',
        // dismiss: this.cookieDismiss,
        // link: this.cookieLinkText,
        // href: environment.Frontend + "/dataprivacy"
      }
    });
  }

  goToTarget(id) {
    try {
      let el = this.myElement.nativeElement.querySelector(id);
      el.scrollIntoView();
    } catch (err) {
      this.router.navigateByUrl('home').then(() => {
        setTimeout(() => {
          let el = this.myElement.nativeElement.querySelector(id);
          el.scrollIntoView();
        }, 50)
      })
    }
  }

  loadScripts() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.cms.loadScripts();
      }
    });
  }

  onChangeQuantity(index, value) {
    this.cms.shopping_cart[index].count = +value
  }

  onRemoveItem(index) {
    this.cms.shopping_cart.splice(index, 1)
  }

  onCheckout() {
    const breakLine = "\n"
    const baseUrl = "https://api.whatsapp.com/send/?phone=9613490117&text=Hello"
    let url = breakLine
    this.cms.shopping_cart.forEach((element, index) => {
      url += element.title + " (x" + element.count + ")" + (index < this.cms.shopping_cart.length - 1 ? breakLine : "")
    });
    url = baseUrl + encodeURIComponent(url)
    window.open(url, '_blank');
  }

}
