import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';

@Injectable()
export class CanActivateThisRoute implements CanActivate {
    constructor(private api: ApiService, private router: Router, ) {
    }

    canActivate(): boolean {
        if (this.api.Is_Logged_In.getValue()) {
            return true;
        } else {
            this.router.navigateByUrl('login');
            return false;
        }
    }
}
