import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SweetAlertService } from 'src/app/core/services/sweetalert.service';
import { CommonService } from 'src/app/core/services/common.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { OptionsInput } from '@fullcalendar/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  options: OptionsInput;
  header = {
    title: '',
    date: '',
    note: '',
    uri: ''
  }

  postWorkout: {
    before: '',
    after: ''
    difficult: '',
    comment: ''
  }

  data: any = [];
  calendarEvents: any = [];
  modaVideo: SafeHtml;

  PostWorkoutLevel = [
    '-',
    'Tired - 1',
    'Tired - 2',
    'Tired - 3',
    'Just Fine - 4',
    'Just Fine - 5',
    'Just Fine - 6',
    'Great - 7',
    'Great - 8',
    'Great - 9',
    'Excellent - 10',
    'Very easy - 11',
    'Easy - 12',
    'Moderate - 13',
    'Hard - 14',
    'Very hard - 15'
  ]

  constructor(private api: ApiService, private sanitizer: DomSanitizer, private swa: SweetAlertService, public cms: CommonService) { }

  ngOnInit() {
    this.options = {
      header: {
        left: 'prev, next, today',
        center: 'title',
        right: 'dayGridMonth, timeGridWeek, timeGridDay',
      },
      allDayText: 'Time',
      defaultView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin]
    };
    this.api.getHistoryTrainings().subscribe(res => {
      var response: any = res;
      this.calendarEvents = response.success
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })

  }

  eventClick(e) {
    this.header.title = e.event.title;
    this.header.date = e.event.start;
    this.header.note = e.event._def.extendedProps.note;
    this.header.uri = e.event._def.extendedProps.uri;

    var training_id = e.event.id;

    this.api.getHistoryTrainingWorkouts(training_id).subscribe(res => {
      var response: any = res;
      response = response.success
      this.data = response.result;
      if (this.data) {
        this.data.forEach(element => {
          element.min_seconds_exerciceRest = this.cms.secondToMinutes(element.exerciceRest);
          element.min_seconds_setRest = this.cms.secondToMinutes(element.setRest);

          if (element.minTime !== 0) {
            element.minTime = this.cms.secondToMinutes(element.minTime);
          }
          if (element.maxTime !== 0) {
            element.maxTime = this.cms.secondToMinutes(element.maxTime);
          }

          if (element.uri) {
            element.videoURL = element.uri.split("embed/")[1]
          }


        });
        this.postWorkout = response.postWorkout;
      }
    }, (err) => {
      this.api.showLoader = false;
      this.swa.ShowAlert('Error', 'Something went wrong!', 'error')
    }, () => {
      this.api.showLoader = false;
    })
  }

  showModal(src) {
    setTimeout(() => {
      let source = '<iframe class="embed-responsive-item" src=' + src + ' allowfullscreen></iframe>'
      this.modaVideo = this.sanitizer.bypassSecurityTrustHtml(source)
    }, 200);
  }

}
